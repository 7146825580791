import {React,useState} from 'react'
import './Enquiryform.css'

const Enquiryform = () => {
  const [isFocused, setIsFocused] = useState(false);


  
  const focushandler = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div>
            {/* <form className='enquiry_form'>
                <div className='heading'>BOOK YOUR DREAM DESTINATION !!</div>
                <input type='text' placeholder='fullname' className='fullname' required></input>
                <br></br>
                <input type='email' placeholder='email' className='email' required></input>
                <br></br>
                <input type='number' placeholder='mobile' className='mobile' required></input>
                <br></br>
                <input type='number' placeholder='no. of person' className='person' required></input>
                <br></br>
                <input type='text' placeholder='country' className='country' required></input>
                <br></br>
                <input type={isFocused ? 'date' : 'text'} placeholder='arrival date' onFocus={focushandler}  onBlur={handleBlur} className='arrival' required></input>
                <br></br>
                <input type={isFocused ? 'date' : 'text'} placeholder='departure date' onFocus={focushandler}  onBlur={handleBlur} className='arrival' required></input>
                <br></br>
                <textarea className='message' placeholder='message' required></textarea>
                <br></br>
                <button type='submit' className='button' >submit</button>

            </form> */}
            <div className='enquirybox'>
              <h1 className="contactheading">CONTACT WITH US</h1>
              <br></br>
              <a href='mailto:Rainbowtravelindia@gmail.com'><p className='emailenquiry'>EMAIL - Rainbowtravelindia@gmail.com</p></a>
              <p className='callenquiry'>Whatsapp - +91-9711443099, +91-9811739850 </p>
            </div>
    </div>
  )
}
export default Enquiryform;
